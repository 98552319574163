<template lang="pug">
  include ../../configs/template
  v-dialog(v-model="modal" max-width="551px" width="100%")
    v-card.modalForm
      div(@click="changeModalState").modalForm-close
        v-icon mdi-close
      v-card-title {{ $t('changePassword') }}
      v-card-text.d-flex.flex-column
        +field-validation('password.new_password', 'pass', '["required", "maxLength", "minLength"]')(
          autocomplete="off"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'").my-3
        +field-validation('password.password_confirmation', 'passwordConfirmation', '["required", "notSameAsPassword"]')(
          autocomplete="off"
          @click:append="showPasswordConfirmation = !showPasswordConfirmation"
          :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswordConfirmation ? 'text' : 'password'")
        div.d-flex.justify-center
          v-btn(@click="changePassword" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapActions } from 'vuex'
import { userPassword } from '@/mixins/validationRules'

export default {
  name: 'ChangePasswordModal',
  data () {
    return {
      password: {
        new_password: null,
        password_confirmation: null
      },
      showPassword: false,
      showPasswordConfirmation: false,
      isLoading: false,
      modal: false
    }
  },
  computed: {
    documentID () {
      return this.$route.params.documentID
    }
  },
  validations () { return { password: userPassword() } },
  methods: {
    ...mapActions(['changeUserPassword']),
    changeModalState () {
      this.modal = !this.modal
    },

    changePassword () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.isLoading = true
      this.changeUserPassword({ id: this.documentID, body: this.password }).then(response => {
        if ([200, 201].includes(response?.code)) this.$notification.success('successChangedPassword')
      }).finally(() => {
        this.changeModalState()
        this.isLoading = false
      })
    }
  }
}
</script>
